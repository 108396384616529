<template>
  <div>
      <section id="dashboard-ecommerce">
            <b-row class="match-height">
                <b-col
                    xl="4"
                    md="6"
                >
                   
                </b-col>
                <b-col
                    xl="8"
                    md="6"
                >
                   
                </b-col>
            </b-row>

            <b-row> 
                <b-col cols="4" v-for="(volet,index) in voletroulant" :key="index">
                    <b-card
                        class="earnings-card"
                >
                    <b-row>
                    <b-col cols="8">
                        <b-card-title class="mb-1">
                        {{volet.titre}}
                        </b-card-title>
                        <div class="font-small-2">
                        This Month
                        </div>
                        <h5 class="mb-1">
                        $4055.56
                        </h5>
                        <b-card-text class="text-muted font-small-2">
                        <span class="font-weight-bolder">68.2%</span><span> more earnings than last month.</span>
                        </b-card-text>
                    </b-col>
                    <b-col cols="4" v-if="volet.etat==1">
                        <!-- chart -->
                        <b-img
                            fluid
                            class="mb-2"
                            :src="require('@/assets/images/voletferme.jpg')"
                        />
                    </b-col>
                    <b-col cols="4" v-if="volet.etat==0">
                        <!-- chart -->
                        <b-img
                            fluid
                            class="mb-2"
                            :src="require('@/assets/images/voletouvert.jpg')"
                        />
                    </b-col>
                    </b-row>
                
                <div class="text-center"> 
                    <div role="group" class="m-l-20 btn-group " >
                        <b-button   size="sm" variant="info" :disabled="volet.etat==0" @click="messageTransmit(volet.action)" >Ouvrir</b-button>
                        <b-button   size="sm" variant="success"  :disabled="volet.etat==1"  @click="messageTransmit(volet.action)" >Fermer</b-button>
                    </div> 
                    <b-button   size="sm" variant="danger"   @click="messageTransmit(volet.stop)" >Stop</b-button>
                </div>
                </b-card>
                </b-col>
            </b-row>
      </section>
  </div>
</template>

<script>
import EcommerceMedal from './EcommerceMedal.vue'
import EcommerceStatistics from './EcommerceStatistics.vue'
  import {axios} from '@/views/alarme/authHeader'
    import {apiurl} from '@/views/alarme/apiurl'
    import { authHeader } from '@/views/alarme/http-constants';
    import moment from 'moment';
    import { $themeColors } from '@themeConfig'
    import {
  BCard, BRow, BCol, BCardTitle, BCardText,BButton,BImg
} from 'bootstrap-vue'
export default {
    components: {
           BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardText,      
    BButton,
    BImg,
    EcommerceMedal,
    EcommerceStatistics,
    },
    data: () => ({
        connection: null,
        detecteurs:[],
        fildeleau:[],
        capteurs:[],
        serveur:null,
        etatserveur:0,
        voletroulant:[
            {
                id:0,
                titre:"Volet chambre",
                etat:0,
                action:"voletChambre",
                stop:"voletChambreArret"

            },
            {
                id:1,
                titre:"Volet Bureau",
                etat:0,
                action:"voletBureau",
                stop:"voletBureauArret"
                
            },
            {
                id:2,
                titre:"Volet Frédo",
                etat:0,
                action:"voletFredo",
                stop:"voletFredoArret"
                
            },
            {
                id:3,
                titre:"Volet Amandine",
                etat:0,
                action:"voletAmandine",
                stop:"voletAmandineArret"
                
            },
        ],
         data: {},
    }),
     created: function() {
             const thisIns=this
            console.log("Démarrage du serveur WebSocket Server 909")
            this.connection = new WebSocket("wss://alarmedub.eu.ngrok.io")
            this.connection.onmessage = function(event) {
                //lorsqu'un message est reçu
               // console.log("Messge recu",JSON.parse(event.data));
                var tableau = []
               JSON.parse(event.data).map(item => {
                    if(item.type=="DetecteursInterieurs"){
                        tableau.push(item)
                    } 
                    if(item.type=="DetecteursExterieurs"){
                        thisIns.detecteurs.push(item)
                    }
                    if(item.type=="timeline"){
                        thisIns.fildeleau.push(item)
                    }
                })
                thisIns.capteurs=tableau
            }

            this.connection.onopen = function(event) {
             //   console.log(event)
                console.log("Successfully connected to the echo websocket server...")
                thisIns.serveur = "Serveur connecté"
                thisIns.etatserveur=1
                thisIns.connection.send(JSON.stringify({type:"message",content:"DetecteursInterieurs"}));
                thisIns.connection.send(JSON.stringify({type:"message",content:"DetecteursExterieurs"}));

            }
            this.connection.onclose = function(event) {
              //  console.log(event)
                console.log("Déconnection du serveur websocket server...")
               thisIns.serveur = "Serveur hors ligne"
                thisIns.etatserveur=0
            }
        },
         methods: {
             moment,
              messageTransmit: function(message) {
                //  console.log(message)
                if(this.etatserveur==1){
                    this.connection.send(JSON.stringify({type:"message",content:message}));
                }else{
                       this.connection = new WebSocket("wss://alarmedub.eu.ngrok.io")
                       this.connection.send(JSON.stringify({type:"message",content:message}));
                }
                
            }
         }

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
</style>